export class SwipeController {
    constructor(startSwipeValueProvider = () => undefined, onStart = () => undefined, onSwipeRecognized = () => undefined, onUpdate = () => undefined) {
        this.startSwipeValueProvider = startSwipeValueProvider;
        this.onStart = onStart;
        this.onSwipeRecognized = onSwipeRecognized;
        this.onUpdate = onUpdate;
        this.currentlySwiping = false;
    }
    onTouchStart(e) {
        if (e.touches.length > 1 && this.currentlySwiping) {
            return;
        }
        this.currentlySwiping = true;
        const touchStartData = {
            startX: e.touches[0].clientX,
            startY: e.touches[0].clientY,
            touchId: e.touches[0].identifier,
            startTime: new Date().getTime(),
            direction: "horizontal",
        };
        this.startValue = this.startSwipeValueProvider();
        this.onStart();
        const touchEndHandle = (e) => {
            window.removeEventListener("touchend", touchEndHandle);
            window.removeEventListener("touchmove", touchMoveHandle);
            this.currentlySwiping = false;
            function getSwipeDirection() {
                if (e.changedTouches.length > 1) {
                    return "none";
                }
                const { startX, startY, touchId, startTime } = touchStartData;
                if (touchId !== e.changedTouches[0].identifier) {
                    return "none";
                }
                const endX = e.changedTouches[0].clientX;
                const endY = e.changedTouches[0].clientY;
                const endTime = new Date().getTime();
                if (endTime - startTime < MIN_SWIPE_TIME) {
                    return "none";
                }
                const deltaX = endX - startX;
                const deltaY = endY - startY;
                const distanceSquared = deltaX * deltaX + deltaY * deltaY;
                if (distanceSquared < MIN_SWIPE_DISTANCE_SQUARED) {
                    return "none";
                }
                const velocityX = (endX - startX) / (endTime - startTime);
                const velocityY = (endY - startY) / (endTime - startTime);
                const absoluteVelocityX = Math.abs(velocityX);
                const absoluteVelocityY = Math.abs(velocityY);
                if (absoluteVelocityX > absoluteVelocityY) {
                    if (velocityX > MIN_SWIPE_VELOCITY) {
                        return "left";
                    }
                    else if (velocityX < -MIN_SWIPE_VELOCITY) {
                        return "right";
                    }
                }
                else if (absoluteVelocityX < absoluteVelocityY) {
                    if (velocityY > MIN_SWIPE_VELOCITY) {
                        return "bottom";
                    }
                    else if (velocityY < -MIN_SWIPE_VELOCITY) {
                        return "top";
                    }
                }
                return "none";
            }
            this.onSwipeRecognized(getSwipeDirection());
        };
        const touchMoveHandle = (e) => {
            this.onUpdate(Object.assign(Object.assign({}, touchStartData), { startValue: this.startValue, currentX: e.touches[0].clientX, currentY: e.touches[0].clientY, currentTime: new Date().getTime() }));
            function cancelSwipeDetection() {
                if (e.changedTouches.length > 1) {
                    return false;
                }
                const { startX, startY, touchId, direction } = touchStartData;
                if (touchId !== e.changedTouches[0].identifier) {
                    return false;
                }
                const endX = e.changedTouches[0].clientX;
                const endY = e.changedTouches[0].clientY;
                const deltaX = Math.abs(endX - startX);
                const deltaY = Math.abs(endY - startY);
                if (direction === "horizontal" && deltaY > 10 && deltaY > deltaX) {
                    return true;
                }
                return false;
            }
            if (cancelSwipeDetection()) {
                window.removeEventListener("touchend", touchEndHandle);
                window.removeEventListener("touchmove", touchMoveHandle);
                this.onSwipeRecognized("none");
            }
            else {
                e.preventDefault();
            }
        };
        window.addEventListener("touchend", touchEndHandle);
        window.addEventListener("touchmove", touchMoveHandle, { passive: false });
    }
}
const MIN_SWIPE_TIME = 100;
const MIN_SWIPE_DISTANCE_SQUARED = 50 * 50;
const MIN_SWIPE_VELOCITY = 0.5;
